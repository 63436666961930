import { ReactNode, memo } from "react";
import { cx } from "class-variance-authority";
import * as Popover from "@radix-ui/react-popover";
import { useAuth } from "lib/firebase/hooks";
import Link from "next/link";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useFlag } from "@unleash/proxy-client-react";
import { LogomarkWithNameBlue } from "assets/vectors/logo";
import { LanguageDropdown } from "components/LanguageSelector";
import { useRouter } from "next/router";
import { graphql } from "lib/gql";
import { useQuery } from "@apollo/client";
import {
    CalendarFilled,
    MenuAlt1Filled,
    PencilFilled,
    StarFilled,
    UserFilled,
} from "./Icons";
import { CloseUnfilled } from "./Icons/Close";
import { Avatar } from "./Avatar";

type DesktopHeaderProps = { className?: string };

// TODO: add voucher link
// TODO: add rewards link

const pageQuery = graphql(`
    query desktopNavProfile {
        myProfile {
            firebaseId
            name
            email
        }
    }
`);

type TFunc = TFunction<["components/DesktopHeader", "common"]>;
const DesktopHeader = memo<DesktopHeaderProps>(({ className }): JSX.Element => {
    const { t } = useTranslation(["components/DesktopHeader", "common"]);
    return (
        <header
            className={cx(
                "relative z-10 mx-auto hidden w-full divide-x-0 divide-y-0.5 divide-solid divide-blue-grey-50 bg-white shadow-md lg:block",
                className,
            )}
        >
            <nav className="w-full py-2">
                <div className="mx-auto flex max-w-[1056px] items-center justify-between">
                    <Link href="/" passHref>
                        <a>
                            <LogomarkWithNameBlue />
                        </a>
                    </Link>
                    <div className="flex items-center gap-3">
                        <LanguageDropdown />
                        <NavLink
                            href="/support"
                            className="hover:text-primary-600"
                        >
                            {t("help", "Help")}
                        </NavLink>
                        <UserMenu t={t} />
                    </div>
                </div>
            </nav>
            <nav className="w-full py-2">
                <NavigationSection t={t} />
            </nav>
        </header>
    );
});
DesktopHeader.displayName = "HeaderNew";
export { DesktopHeader };

const UserMenu = ({ t }: { t: TFunc }): JSX.Element => {
    const { user, isInitialised, signout } = useAuth();
    const { data, loading } = useQuery(pageQuery, { skip: !user });
    const url = user?.photoURL;
    const myProfile = data?.myProfile;
    const name = myProfile?.name;
    if (loading || !isInitialised) return <div />;
    if (!user)
        return (
            <>
                <Link href="/signup">
                    <a className="typography-h4 link-plain my-1.5 rounded-[20px] border border-solid border-transparent px-4 py-2.5 font-bold text-blue-grey-900 transition-colors hover:bg-primary-50 focus:border-blue-grey-50 focus:bg-blue-grey-20 focus:text-primary-600">
                        {t("common:signUp", "Sign up")}
                    </a>
                </Link>
                <Link href="/login">
                    <a className="typography-h4 link-plain my-1.5 rounded bg-primary px-4 py-2.5 text-white transition-colors hover:bg-primary/90 hover:text-white">
                        {t("common:logIn", "Log in")}
                    </a>
                </Link>
            </>
        );
    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button className="flex cursor-pointer items-center gap-3 rounded-[20px] border-none bg-transparent px-3 py-2 outline outline-1 outline-blue-grey-50">
                    <MenuAlt1Filled className="size-7 text-blue-grey" />
                    <Avatar
                        text={name?.charAt(0)}
                        src={url}
                        size="40"
                        shadow="lg"
                    />
                </button>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    align="end"
                    className="z-50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
                >
                    <div className="flex h-[476px] w-[345px] flex-col gap-8 rounded-[20px] bg-primary-50 px-4 py-5 shadow-md">
                        <div className="flex flex-col items-center">
                            <div className="relative">
                                <Avatar
                                    text={name?.charAt(0)}
                                    src={url}
                                    size="96"
                                    shadow="lg"
                                />
                                <Link href="/user/profile">
                                    <div className="absolute bottom-0 left-auto right-[0.5px] top-auto flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-white">
                                        <PencilFilled className="size-3 text-blue-grey-600" />
                                    </div>
                                </Link>
                            </div>
                            <div className="mt-4 text-center">
                                <div className="typography-h3 font-bold text-blue-grey-900">
                                    {t("hiUser", "Hi, {{name}}!", {
                                        name: myProfile?.name,
                                    })}
                                </div>
                                <div className="typography-tiny text-blue-grey-400">
                                    {myProfile?.email}
                                </div>
                            </div>
                        </div>
                        <nav>
                            <div className="divide-x-0 divide-y-[0.5px] divide-solid divide-primary-100 rounded-[20px] bg-white py-2">
                                <UserMenuLink href="/user/upcoming">
                                    <CalendarFilled className="text-primary-300" />
                                    {t("common:upcoming", "Upcoming")}
                                </UserMenuLink>
                                <UserMenuLink href="/user">
                                    <UserFilled className="text-primary-300" />
                                    {t("common:my_profile", "My profile")}
                                </UserMenuLink>
                            </div>
                            <div className="mt-5 divide-x-0 divide-y-[0.5px] divide-solid divide-primary-100 rounded-[20px] bg-white py-2">
                                <UserMenuLink href="/about">
                                    <StarFilled className="text-primary-300" />
                                    {t(
                                        "common:aboutCourtsite",
                                        "About Courtsite",
                                    )}
                                </UserMenuLink>
                            </div>
                            <div className="grid-col-1 mt-8 grid">
                                <button
                                    className="typography-overline-lg cursor-pointer border-0 bg-transparent text-blue-grey hover:underline"
                                    onClick={() => signout()}
                                >
                                    {t("common:logOut", "Log out")}
                                </button>
                            </div>
                        </nav>
                        <Popover.Close
                            className="absolute right-4 top-5 border-0 bg-transparent"
                            aria-label="Close"
                        >
                            <CloseUnfilled className="size-7 cursor-pointer text-blue-grey-900" />
                        </Popover.Close>
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};

const UserMenuLink = ({
    children,
    href,
}: {
    children?: ReactNode;
    href: string;
}): JSX.Element => (
    <Link href={href}>
        <a className="typography-main remove-styles-a flex items-center gap-3 px-4 py-3 text-blue-grey-500">
            {children}
        </a>
    </Link>
);

const NavLink = ({
    href,
    children,
    className,
}: {
    href: string;
    children: ReactNode;
    className?: string;
}): JSX.Element => (
    <Link href={href}>
        <a
            className={cx(
                "typography-h4 h-full cursor-pointer rounded-[20px] border-none bg-transparent px-2.5 py-2 text-blue-grey-900 hover:bg-blue-grey-50 hover:no-underline focus:bg-blue-grey-20 focus:text-blue-grey-900 focus:outline focus:outline-1 focus:outline-blue-grey-100",
                className,
            )}
        >
            {children}
        </a>
    </Link>
);

const NavigationSection = ({ t }: { t: TFunc }): JSX.Element => {
    const { pathname } = useRouter();
    const voucherFlag = useFlag("feature.voucher");
    const rewardFlag = useFlag("feature.reward");
    const headerRoutes = [
        { name: t("common:explore", "Explore"), pathname: "/explore/category" },
        { name: t("common:book", "Book"), pathname: "/search" },
        { name: t("common:games", "Games"), pathname: "/explore/games" },
        { name: t("common:deals", "Deals"), pathname: "/deals" },
    ];

    return (
        <div className="mx-auto flex max-w-[1056px] justify-between">
            <div className="flex items-center gap-0.5">
                {headerRoutes.map((r) => (
                    <NavLink
                        key={r.name as string}
                        href={r.pathname}
                        className={cx(
                            "hover:text-blue-grey-900 active:bg-transparent active:font-bold active:text-blue-grey-900",
                            pathname === r.pathname && "font-bold",
                        )}
                    >
                        {r.name}
                    </NavLink>
                ))}
            </div>
            <div
                data-voucher={voucherFlag}
                data-reward={rewardFlag}
                className="group flex w-fit gap-4"
            >
                <NavLink
                    href="/"
                    className="hidden py-1 group-data-[voucher=true]:block"
                >
                    <IconTextDiv
                        icon="/images/header/voucher.png"
                        text={t("common:voucher", "Voucher")}
                    />
                </NavLink>
                <NavLink
                    href="/"
                    className="hidden py-1 group-data-[reward=true]:block"
                >
                    <IconTextDiv
                        icon="/images/header/reward.png"
                        text={t("common:reward", "Reward")}
                    />
                </NavLink>
            </div>
        </div>
    );
};

const IconTextDiv = ({
    icon,
    text,
}: {
    icon: string;
    text: string;
}): JSX.Element => (
    <div className="flex items-center gap-2">
        <div
            className="size-8 bg-contain"
            style={{ backgroundImage: `url(${icon})` }}
        />
        {text}
    </div>
);
